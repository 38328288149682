import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appDynamicCompHost]'
})
export class DynamicCompHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
