export interface IOnlineTestTime {
  seconds?: number;
  formatted: string;
}

export interface IOnlineTestSelected extends IOnlineClientTest {
  test: IOnlineTest;
  answers: ITestAnswer[];
}



export interface IOnlineTest {
  studentId: number;
  type: string;
  testId: number;
  eligible: boolean;
  name: string;
  testTime: IOnlineTestTime;
  template: string;
}


export class OnlineTestStorage {
  key = 'start';
  available = new Array<IOnlineTest>();
  selected: IOnlineTestSelected;
}

export interface IOnlineClientTest {
  identifier: string;
  started: Date;
  endAt: Date;
  timeRemaining: number;
}


export interface ITestQuestion {
  testQuestionId: number;
  number: number;
  question: string;
}

export interface ITestAnswer extends ITestQuestion {
  answer: string;
}
