import { StorageService } from './storage.service';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiParams, OltApiService, OltUtility } from '@outerlimitstech/ngx-app-core';
import { Observable, of } from 'rxjs';
import { IOnlineClientTest, IOnlineTest, ITestAnswer, OnlineTestStorage } from '../models/online-test';


@Injectable({
  providedIn: 'root'
})
export class OnlineTestService {

  constructor(
    private apiService: OltApiService,
    private storageService: StorageService
  ) { }

  getClientTests(dob: Date, passcode: string): Observable<IOnlineTest[]> {
    const params = new ApiParams();
    params.append('dob', dob.toISOString());
    params.append('identifier', passcode);
    return this.apiService.doGet<IOnlineTest[]>('online-test', params)
      .pipe(switchMap(json => {
        const data = this.storageService.onlineTest;
        data.available = json;
        this.storageService.onlineTest = data;
        return of(this.storageService.onlineTest.available);
      }));
  }

  get(): Observable<OnlineTestStorage> {
    return of(this.storageService.onlineTest);
  }

  complete(): Observable<boolean> {
    if (this.storageService.onlineTest?.selected?.identifier != null) {
      return this.apiService.doPut(`online-test/${this.storageService.onlineTest.selected.identifier}/complete`, null);
    }
    return of(true);
  }


  startTest(test: IOnlineTest): Observable<boolean> {
    return this.apiService.doPost<IOnlineClientTest>('online-test', test)
      .pipe(switchMap(json => {
        const data = this.storageService.onlineTest;
        data.selected = {
          test,
          identifier: json.identifier,
          started: json.started,
          endAt: json.endAt,
          timeRemaining: json.timeRemaining,
          answers: []
        };
        data.available = [].concat(data.available.filter(p => p.testId !== test.testId));
        this.storageService.onlineTest = data;
        return of(true);
      }));
  }

  getQuestions(): Observable<ITestAnswer[]> {
    const data = this.storageService.onlineTest;
    if (this.storageService.onlineTest?.selected?.answers?.length > 0) {
      return of(this.storageService.onlineTest.selected.answers);
    }

    return this.apiService.doGet<ITestAnswer[]>(`online-test/${this.storageService.onlineTest.selected.identifier}/questions`)
      .pipe(switchMap(questions => {
        data.selected.answers = [];
        questions.forEach(question => {
          question.answer = null;
          data.selected.answers.push(question);
        });
        this.storageService.onlineTest = data;
        return of(this.storageService.onlineTest.selected.answers);
      }));
  }

  saveAnswer(testAnswer: ITestAnswer): void {
    const postData = {
      testQuestionId: testAnswer.testQuestionId,
      answer: testAnswer.answer
    };

    this.apiService.doPut<boolean>(`online-test/${this.storageService.onlineTest.selected.identifier}/answers`, postData)
      .subscribe(result => {
        const data = this.storageService.onlineTest;
        const question = data.selected.answers.find(p => p.testQuestionId === OltUtility.toInt(testAnswer.testQuestionId));
        question.answer = testAnswer.answer;
        this.storageService.onlineTest = data;
      });
  }
}
