import { Injectable } from '@angular/core';
import { CONSTANTS } from '../models/constants';
import { OnlineTestStorage } from '../models/online-test';
import { BaseStorageService } from 'bgcslib';

@Injectable({
  providedIn: 'root'
})
export class StorageService extends BaseStorageService {

  
  get onlineTest(): OnlineTestStorage | null {
    const value = sessionStorage.getItem(CONSTANTS.Storage.Test);
    if (value != null) {
      return JSON.parse(value);
    }
    return null;
  }
  set onlineTest(value: OnlineTestStorage | null) {
    this.saveSessionStorage(CONSTANTS.Storage.Test, JSON.stringify(value));
  }

  clear() {
    sessionStorage.removeItem(CONSTANTS.Storage.Test);
  }

}
